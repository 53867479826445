<template>
    <div>
        <ListSingleSpieleByTurnier :turnierID="turnierID"/>
    </div>
</template>

<script>
import ListSingleSpieleByTurnier from '@/components/Spiele/ListSingleSpieleByTurnier.vue'

export default {
    name: 'ListSingleSpiele',
    props: ['turnierID'],
    components: {
        ListSingleSpieleByTurnier
    }
}
</script>
